* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Cera Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 14px;
  background: #f7f7f7;
  color: #353535;
}

a {
  color: #353535;
}
