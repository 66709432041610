@font-face {
  font-family: 'Cera Pro';
  src: local('CeraPro-Bold'),
      url('../fonts/CeraPro-Bold.woff2') format('woff2'),
      url('../fonts/CeraPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: local('CeraPro-Regular'),
      url('../fonts/CeraPro-Regular.woff2') format('woff2'),
      url('../fonts/CeraPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}